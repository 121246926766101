<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LOAD INVENTORY</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-3">
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="particulars_id"
                dense
                outlined
                label="Particulars"
                :items="particulars_items"
                item-value="id"
                item-text="particulars"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              md="3"
              cols="12"
            >
              <v-text-field
                v-model="date"
                label="Date"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col
              md="3"
              cols="12"
            >
              <v-text-field
                v-model="quantity"
                label="Quantity"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-radio-group
                v-model="row"
                row
                dense
                @change="reseter_search"
              >
                Donated By: &nbsp;&nbsp;
                <v-radio
                  label="Member"
                  value="is_member"
                ></v-radio>
                <v-radio
                  label="District"
                  value="is_district"
                ></v-radio>
                <v-radio
                  label="Others"
                  value="is_others"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              md="12"
              cols="12"
              v-if="row!='is_others'"
            >
              <v-autocomplete
                v-model="search"
                :items="search_items"
                :loading="isLoading"
                @keyup.enter="searching($event.target.value)"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                label="Search"
                placeholder="Press Enter to Search "
                :prepend-icon="icons.mdiAccountSearchOutline"
                @change="get_search_items_info"
              ></v-autocomplete>
            </v-col>
            <v-col
              md="12"
              cols="12"
              v-if="row==='is_others'"
            >
              <v-text-field
                v-model="location"
                label="Details of Donator"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_staff_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-uppercase">
            ID
          </th>
          <th class="text-uppercase">
            Date
          </th>
          <th class="text-uppercase">
            Donated By
          </th>
          <th class="text-uppercase">
            Particulars
          </th>
          <th class="text-uppercase">
            Quantity
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in user_items"
          :key="item.id"
        >
          <td>
            {{ item.id }}
          </td>
          <td>
            {{ item.date }}
          </td>
          <td>
            {{ item.member==null?item.district==null?item.donator:item.district.district_name:item.member.last_name+', '+item.member.first_name+' '+item.member.middle_name }}
          </td>
          <td>
            {{ item.particulars.particulars }}
          </td>
          <td>
            {{ item.quantity }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountSearchOutline,
    mdiAlertOutline,
    mdiCloudUploadOutline,
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return {
        particulars_id: '',
        particulars_items: [],

        saving: false,
        alert: false,
        alert_message: '',

        quantity: '',

        user_items: [],

        row: null,
        isLoading: false,
        search: '',
        search_items: [],

        member_id: null,
        district_id: null,
        location: null,
        date: '',
      }
    },
    created() {
      this.list_of_all_particulars()
        .then(response => {
          this.particulars_items = response.data
        })
      this.list_of_inventory_report()
        .then(response => {
          this.user_items = response.data
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['user_id']),
    },
    methods: {
      ...mapActions('branch', ['get_list_of_all_active_branch']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory_particulars', ['list_of_all_particulars',]),
      ...mapActions('inventory_report', ['list_of_inventory_report', 'load_new_inventory_report']),
      ...mapActions('members_information', ['search_all_members']),
      ...mapActions('district', ['search_all_district_club']),
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.quantity = ''
        this.list_of_inventory_report()
          .then(response => {
            this.user_items = response.data
          })
      },
      reseter_search() {
        this.search_member = ''
        this.search_items = []
      },
      searching(value) {
        this.member_id = null
        this.district_id = null
        this.location = null
        this.isLoading = true
        if (this.row === 'is_member') {
          this.search_all_members({
            search_word: value
          })
            .then(response => {
              this.search_items = response.data
              this.isLoading = false
            })
        } else if (this.row === 'is_district') {
          this.search_all_district_club({
            search_word: value,
          })
            .then(response => {
              this.isLoading = false
              this.search_items = response.data
            })
        }
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.member_id = null
            this.district_id = null
            this.location = null
            if (this.row === 'is_member') {
              this.member_id = this.search_items[index].id
            } else if (this.row === 'is_district') {
              this.district_id = this.search_items[index].id
            }
          } else {
            this.member_id = null
            this.district_id = null
            this.location = null
          }
        } else {
          this.member_id = null
          this.district_id = null
          this.location = null
        }
      },
      save_staff_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('particulars_id', this.particulars_id);
          data.append('quantity', this.quantity);
          data.append('created_id', this.user_id);
          data.append('date', this.date);
          data.append('member_id', this.member_id);
          data.append('district_id', this.district_id);
          data.append('location', this.location);
          this.load_new_inventory_report(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
